<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo">
  </div>
  <div class="header">
    <h1>BELBINOV TEST</h1>
    <h3>Timske uloge</h3>
    <h3>Upitnik za sopstvenu procenu</h3>
    <p>Ovaj upitnik će Vam pomoći da: </p>
    <ul>
      <li>Poboljšate uloge koje Vam najviše leže, da ih razjasnite, kako bi na najbolji način doprineli timskom radu. </li>
      <li>Postanete svesni da ne vredi “igrati uloge” koje Vam nisu bliske. Bolje je ulagati u sebe, poboljšati svoju ulogu u timu i odabrati tim ljudi sa kojima ćete se u zajedničkom radu dopunjavati. </li>
    </ul>

    <h3>UPITNIK</h3>
    <p>Za svaki od sedam delova upitnika na raspolaganju Vam stoji 10 bodova. Rasporedite ih na svaku od rečenica, ali tako da najveći broj bodova dodelite rečenici koja najtačnije opisuje Vaše ponašanje u grupi. Ukoliko samo jedna rečenica najtačnije oslikava Vašu ulogu u grupi – možete za nju staviti svih 10 bodova.</p>
  </div>

<div id="t1">


  <table id="table1">
    <tr>
      <th colspan="4">Mislim da mogu doprineti grupnom radu, zato što…. </th>
    </tr>
    <tr>
      <td>A.</td>
      <td>… sam u stanju da brzo uočim nove mogućnosti i znam kako da ih iskoristim na najbolji mogući način</td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena11" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment11"> +</button>
        <button type="button" name="button" @click="decrement11"> -</button>
      </td>
    </tr>
    <tr>
      <td>B.</td>
      <td>… dobro sarađujem sa različitim tipovima osoba </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena12" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment12"> +</button>
        <button type="button" name="button"  @click="decrement12"> -</button>
      </td>
    </tr>
    <tr>
      <td>C.</td>
      <td>… jedna od mojih prednosti je sposobnost da stvaram nove ideje </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena13" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment13"> +</button>
        <button type="button" name="button"  @click="decrement13"> -</button>
      </td>
    </tr>
    <tr>
      <td>D.</td>
      <td>… znam kako da izvučem najbolje od ostalih članova grupe (ono što rade najbolje, kako bi se postigli grupni ciljevi i ispunili zadaci) </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena14" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment14"> +</button>
        <button type="button" name="button"  @click="decrement14"> -</button>
      </td>
    </tr>
    <tr>
      <td>E.</td>
      <td>… moja osnovna veština je to što znam kako da se brzo i efikasno završi posao </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena15" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment15"> +</button>
        <button type="button" name="button"  @click="decrement15"> -</button>
      </td>
    </tr>
    <tr>
      <td>F.</td>
      <td>… u stanju sam da prihvatim sopstvenu “nepopularnost” na neko vreme, ukoliko će to dovesti do željenih rezultata </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena16" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment16"> +</button>
        <button type="button" name="button"  @click="decrement16"> -</button>
      </td>
    </tr>
    <tr>
      <td>G.</td>
      <td>… uglavnom sam u stanju da razlikujem “realno” od “mogućeg” u smislu postizanja uspeha </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena17" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment17"> +</button>
        <button type="button" name="button"  @click="decrement17"> -</button>
      </td>
    </tr>
    <tr>
      <td>H.</td>
      <td>… obično bez ikakvih predrasuda predlažem alternativna rešenja </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena18" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment18"> +</button>
        <button type="button" name="button"  @click="decrement18"> -</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Preostalo bodova:</td>
      <td colspan="2"><input for="" class="ocenaLbl" v-model="preostalo" placeholder="" readonly/> </td>
    </tr>
  </table>
</div>

<div id="t2">

  <table id="table2">
    <tr>
      <th colspan="4">Ukoliko u grupnom radu imam određenih problema/neuspeha, to je zato što … </th>
    </tr>
    <tr>
      <td>A.</td>
      <td>… ne mogu da se smirim dok sve nije dovedeno “pod konac” i dok stvari nisu pod kontrolom </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena21" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment21"> +</button>
        <button type="button" name="button" @click="decrement21"> -</button>
      </td>
    </tr>
    <tr>
      <td>B.</td>
      <td>… ponekad pridajem previše pažnje idejama koje se neće realizovati 	</td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena22" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment22"> +</button>
        <button type="button" name="button"  @click="decrement22"> -</button>
      </td>
    </tr>
    <tr>
      <td>C.</td>
      <td>… uglavnom previše pričam kada grupa radi na novim idejama </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena23" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment23"> +</button>
        <button type="button" name="button"  @click="decrement23"> -</button>
      </td>
    </tr>
    <tr>
      <td>D.</td>
      <td>… mi moja hladna spoljašnost otežava da se pridružim svojim kolegama entuzijastima </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena24" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment24"> +</button>
        <button type="button" name="button"  @click="decrement24"> -</button>
      </td>
    </tr>
    <tr>
      <td>E.</td>
      <td>… ponekad ostavljam utisak osobe koja vrši preveliki pritisak i postavlja se autoritativno, ukoliko nešto zaista mora biti urađeno </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena25" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment25"> +</button>
        <button type="button" name="button"  @click="decrement25"> -</button>
      </td>
    </tr>
    <tr>
      <td>F.</td>
      <td>… me je teško postavljati na “prvu liniju”, jer se osećam odgovornim/odgovornom za grupnu atomosferu </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena26" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment26"> +</button>
        <button type="button" name="button"  @click="decrement26"> -</button>
      </td>
    </tr>
    <tr>
      <td>G.</td>
      <td>… mi se dešava da mislim o onome što tek treba da kažem, pa tako “izgubim nit” i ne znam šta se dešava </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena27" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment27"> +</button>
        <button type="button" name="button"  @click="decrement27"> -</button>
      </td>
    </tr>
    <tr>
      <td>H.</td>
      <td>… moje kolege misle da bespotrebno obraćam previše pažnje na detalje i mogućnosti da se dogodi nešto neplanirano </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena28" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment28"> +</button>
        <button type="button" name="button"  @click="decrement28"> -</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Preostalo bodova:</td>
      <td colspan="2"><input for="" class="ocenaLbl" v-model="preostalo2" placeholder="" readonly/> </td>
    </tr>
  </table>
</div>

<div id="t3">

  <table id="table3">
    <tr>
      <th colspan="4">Kada sa ostalima radim na pripremi projekta …  </th>
    </tr>
    <tr>
      <td>A.</td>
      <td>… trudim se da ih ubedim, ne vršeći pritisak na njih  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena31" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment31"> +</button>
        <button type="button" name="button" @click="decrement31"> -</button>
      </td>
    </tr>
    <tr>
      <td>B.</td>
      <td>… moja “budućnost” sprečava greške 	</td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena32" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment32"> +</button>
        <button type="button" name="button"  @click="decrement32"> -</button>
      </td>
    </tr>
    <tr>
      <td>C.</td>
      <td>… spreman/spremna sam da podstaknem na delovanje ukoliko mi se učini da se na sastanku gubi vreme, kako se ne bi izgubio iz vida osnovni smisao sastanka 	 </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena33" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment33"> +</button>
        <button type="button" name="button"  @click="decrement33"> -</button>
      </td>
    </tr>
    <tr>
      <td>D.</td>
      <td>… obično sam vrlo originalan/originalna </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena34" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment34"> +</button>
        <button type="button" name="button"  @click="decrement34"> -</button>
      </td>
    </tr>
    <tr>
      <td>E.</td>
      <td>… pokušavam da usmerim grupu na dobre ideje </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena35" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment35"> +</button>
        <button type="button" name="button"  @click="decrement35"> -</button>
      </td>
    </tr>
    <tr>
      <td>F.</td>
      <td>… pozivam se na najnovije vesti, otkrića i rezultate istraživanja, koji se tiču određene teme o kojoj se razgovara </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena36" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment36"> +</button>
        <button type="button" name="button"  @click="decrement36"> -</button>
      </td>
    </tr>
    <tr>
      <td>G.</td>
      <td>… ubeđen/a sam da moj sud pomaže u donošenju ispravnih odluka </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena37" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment37"> +</button>
        <button type="button" name="button"  @click="decrement37"> -</button>
      </td>
    </tr>
    <tr>
      <td>H.</td>
      <td>… pravi sam stručnjak u organizovanju najbitnijih etapa rada </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena38" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment38"> +</button>
        <button type="button" name="button"  @click="decrement38"> -</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Preostalo bodova:</td>
      <td colspan="2"><input for="" class="ocenaLbl" v-model="preostalo3" placeholder="" readonly/> </td>
    </tr>
  </table>
</div>

<div id="t3">

  <table id="table4">
    <tr>
      <th colspan="4">Kada radim u grupi, jedna od mojih glavnih karakteristika je da …  </th>
    </tr>
    <tr>
      <td>A.</td>
      <td>… sam zaista zainteresovan/a da bolje upoznam svoje kolege </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena41" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment41"> +</button>
        <button type="button" name="button" @click="decrement41"> -</button>
      </td>
    </tr>
    <tr>
      <td>B.</td>
      <td>… se ne plašim mogućnosti da se ne složim sa većinom </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena42" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment42"> +</button>
        <button type="button" name="button"  @click="decrement42"> -</button>
      </td>
    </tr>
    <tr>
      <td>C.</td>
      <td>… sam obično u stanju da pronađem argumente protiv pogrešnih stanovišta </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena43" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment43"> +</button>
        <button type="button" name="button"  @click="decrement43"> -</button>
      </td>
    </tr>
    <tr>
      <td>D.</td>
      <td>… sam posebno talentovan/a za primenjivanje ideja </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena44" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment44"> +</button>
        <button type="button" name="button"  @click="decrement44"> -</button>
      </td>
    </tr>
    <tr>
      <td>E.</td>
      <td>… pažljivo posmatram i umem da iznenadim ostale nečim neočekivanim  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena45" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment45"> +</button>
        <button type="button" name="button"  @click="decrement45"> -</button>
      </td>
    </tr>
    <tr>
      <td>F.</td>
      <td>… sam savršen/a u svemu što radim  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena46" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment46"> +</button>
        <button type="button" name="button"  @click="decrement46"> -</button>
      </td>
    </tr>
    <tr>
      <td>G.</td>
      <td>… sam spreman/spremna da uspostavljam i koristim kontakte van grupe  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena47" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment47"> +</button>
        <button type="button" name="button"  @click="decrement47"> -</button>
      </td>
    </tr>
    <tr>
      <td>H.</td>
      <td>… znam kako da donesem prave odluke, čak i ako je prisutno više različitih aspekata i argumenata  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena48" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment48"> +</button>
        <button type="button" name="button"  @click="decrement48"> -</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Preostalo bodova:</td>
      <td colspan="2"><input for="" class="ocenaLbl" v-model="preostalo4" placeholder="" readonly/> </td>
    </tr>
  </table>
</div>


<div id="t3">

  <table id="table5">
    <tr>
      <th colspan="4">Volim da radim u grupi zato što …</th>
    </tr>
    <tr>
      <td>A.</td>
      <td>… volim da analiziram situacije i razmatram različite mogućnosti </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena51" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment51"> +</button>
        <button type="button" name="button" @click="decrement51"> -</button>
      </td>
    </tr>
    <tr>
      <td>B.</td>
      <td>… sam zainteresovan/a za pronalaženje praktičnog rešenja za neki problem </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena52" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment52"> +</button>
        <button type="button" name="button"  @click="decrement52"> -</button>
      </td>
    </tr>
    <tr>
      <td>C.</td>
      <td>… volim da znam da sam od koristi u međusobnim odnosima tokom rada </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena53" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment53"> +</button>
        <button type="button" name="button"  @click="decrement53"> -</button>
      </td>
    </tr>
    <tr>
      <td>D.</td>
      <td>… volim da utičem na donošenje odluka 	 </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena54" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment54"> +</button>
        <button type="button" name="button"  @click="decrement54"> -</button>
      </td>
    </tr>
    <tr>
      <td>E.</td>
      <td>… volim da imam veze sa ljudima koji imaju nešto da ponude </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena55" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment55"> +</button>
        <button type="button" name="button"  @click="decrement55"> -</button>
      </td>
    </tr>
    <tr>
      <td>F.</td>
      <td>… sam sposoban/sposobna da pomirim različite stavove ljudi koji su važni za rad  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena56" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment56"> +</button>
        <button type="button" name="button"  @click="decrement56"> -</button>
      </td>
    </tr>
    <tr>
      <td>G.</td>
      <td>… svu pažnju posvećujem izvršenju zadataka </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena57" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment57"> +</button>
        <button type="button" name="button"  @click="decrement57"> -</button>
      </td>
    </tr>
    <tr>
      <td>H.</td>
      <td>… pronalazim one oblasti koje stimulišu moju maštu </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena58" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment58"> +</button>
        <button type="button" name="button"  @click="decrement58"> -</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Preostalo bodova:</td>
      <td colspan="2"><input for="" class="ocenaLbl" v-model="preostalo5" placeholder="" readonly/> </td>
    </tr>
  </table>
</div>


<div id="t3">

  <table id="table6">
    <tr>
      <th colspan="4">Ukoliko neočekivano dobijem zadatak koji treba da se obavi za kratko vreme i sa ljudima koji nemaju pozitivan stav … </th>
    </tr>
    <tr>
      <td>A.</td>
      <td>… radije sedim u uglu i razmišljam o tome kako da izađem iz čorsokaka </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena61" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment61"> +</button>
        <button type="button" name="button" @click="decrement61"> -</button>
      </td>
    </tr>
    <tr>
      <td>B.</td>
      <td>… spreman/spremna sam da radim sa osobom koja ima najpozitivniji mogući stav  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena62" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment62"> +</button>
        <button type="button" name="button"  @click="decrement62"> -</button>
      </td>
    </tr>
    <tr>
      <td>C.</td>
      <td>… pokušavam da pojednostavim zadatak, utvrđujući šta ko može da uradi </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena63" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment63"> +</button>
        <button type="button" name="button"  @click="decrement63"> -</button>
      </td>
    </tr>
    <tr>
      <td>D.</td>
      <td>… moj prirodni dar za “hitne stvari” mi omogućava da stvari privedem kraju </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena64" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment64"> +</button>
        <button type="button" name="button"  @click="decrement64"> -</button>
      </td>
    </tr>
    <tr>
      <td>E.</td>
      <td>… uspevam da sačuvam hladnokrvnost i razmišljam racionalno </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena65" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment65"> +</button>
        <button type="button" name="button"  @click="decrement65"> -</button>
      </td>
    </tr>
    <tr>
      <td>F.</td>
      <td>… uprkos pritisku vidim cilj pred sobom </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena66" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment66"> +</button>
        <button type="button" name="button"  @click="decrement66"> -</button>
      </td>
    </tr>
    <tr>
      <td>G.</td>
      <td>… spreman/spremna sam da preduzmem konstruktivnu reorganizaciju ukoliko ustanovim da nema napredovanja </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena67" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment67"> +</button>
        <button type="button" name="button"  @click="decrement67"> -</button>
      </td>
    </tr>
    <tr>
      <td>H.</td>
      <td>… iniciram razgovor, kako bismo došli do novih ideja i rešenja </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena68" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment68"> +</button>
        <button type="button" name="button"  @click="decrement68"> -</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Preostalo bodova:</td>
      <td colspan="2"><input for="" class="ocenaLbl" v-model="preostalo6" placeholder="" readonly/> </td>
    </tr>
  </table>
</div>

<div id="t3">

  <table id="table6">
    <tr>
      <th colspan="4">Imam problema u radu u grupi jer … </th>
    </tr>
    <tr>
      <td>A.</td>
      <td>… imam običaj da izrazim svoje neslaganje sa onima koji, po mom mišljenju, koče napredovanje 	</td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena71" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment71"> +</button>
        <button type="button" name="button" @click="decrement71"> -</button>
      </td>
    </tr>
    <tr>
      <td>B.</td>
      <td>… sam previše analitičan/analitična i nedovoljno koristim svoju intuiciju, što nekima može da smeta </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena72" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment72"> +</button>
        <button type="button" name="button"  @click="decrement72"> -</button>
      </td>
    </tr>
    <tr>
      <td>C.</td>
      <td>… moja želja da se stvari dobro odvijaju može biti kontraproduktivna  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena73" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment73"> +</button>
        <button type="button" name="button"  @click="decrement73"> -</button>
      </td>
    </tr>
    <tr>
      <td>D.</td>
      <td>… brzo mi postane dosadno i očekujem da me ostali stimulišu i motivišu </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena74" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment74"> +</button>
        <button type="button" name="button"  @click="decrement74"> -</button>
      </td>
    </tr>
    <tr>
      <td>E.</td>
      <td>… imam teškoća kada mi cilj nije dovoljno jasan </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena75" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment75"> +</button>
        <button type="button" name="button"  @click="decrement75"> -</button>
      </td>
    </tr>
    <tr>
      <td>F.</td>
      <td>… ponekad mi je teško da precizno objasnim svoje argument  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena76" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment76"> +</button>
        <button type="button" name="button"  @click="decrement76"> -</button>
      </td>
    </tr>
    <tr>
      <td>G.</td>
      <td>… ponekad od drugih zahtevam ono što ni sam/sama nisam u stanju da uradim </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena77" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment77"> +</button>
        <button type="button" name="button"  @click="decrement77"> -</button>
      </td>
    </tr>
    <tr>
      <td>H.</td>
      <td>… ukoliko primetim suprotstavljanje, oklevam u iznošenju svog stava  </td>
      <td class="ocenaTD">
        <input for="" class="ocenaLbl" v-model="ocena78" placeholder="" readonly/>
      </td>
      <td>
        <button type="button" name="button" @click="increment78"> +</button>
        <button type="button" name="button"  @click="decrement78"> -</button>
      </td>
    </tr>
    <tr>
      <td colspan="2">Preostalo bodova:</td>
      <td colspan="2"><input for="" class="ocenaLbl" v-model="preostalo7" placeholder="" readonly/> </td>
    </tr>
  </table>
</div>

<div class="submitDiv">
<button class="btn btn-primary" @click="submitBelbinThis">Submit</button>
</div>

</template>

<script>
import {mapActions,mapGetters} from 'vuex';
export default {
  computed: mapGetters(['user']),
  data(){
    return{
      ocena11:0,
      ocena12:0,
      ocena13:0,
      ocena14:0,
      ocena15:0,
      ocena16:0,
      ocena17:0,
      ocena18:0,
      ocena21:0,
      ocena22:0,
      ocena23:0,
      ocena24:0,
      ocena25:0,
      ocena26:0,
      ocena27:0,
      ocena28:0,
      ocena31:0,
      ocena32:0,
      ocena33:0,
      ocena34:0,
      ocena35:0,
      ocena36:0,
      ocena37:0,
      ocena38:0,
      ocena41:0,
      ocena42:0,
      ocena43:0,
      ocena44:0,
      ocena45:0,
      ocena46:0,
      ocena47:0,
      ocena48:0,
      ocena51:0,
      ocena52:0,
      ocena53:0,
      ocena54:0,
      ocena55:0,
      ocena56:0,
      ocena57:0,
      ocena58:0,
      ocena61:0,
      ocena62:0,
      ocena63:0,
      ocena64:0,
      ocena65:0,
      ocena66:0,
      ocena67:0,
      ocena68:0,
      ocena71:0,
      ocena72:0,
      ocena73:0,
      ocena74:0,
      ocena75:0,
      ocena76:0,
      ocena77:0,
      ocena78:0,
      preostalo:10,
      preostalo2:10,
      preostalo3:10,
      preostalo4:10,
      preostalo5:10,
      preostalo6:10,
      preostalo7:10,
    }
  },
  methods:{
      ...mapActions(['submitBelbin']),
    submitBelbinThis(){

      let SO = 0, RV = 0, OA = 0, OI = 0, OK = 0, S = 0, CT = 0, P = 0;
      SO = this.ocena17 + this.ocena21 + this.ocena38 + this.ocena44 + this.ocena52 + this.ocena66 + this.ocena75;
      RV = this.ocena14 + this.ocena22 + this.ocena31 + this.ocena48 + this.ocena56 + this.ocena63 + this.ocena77;
      OA = this.ocena16 + this.ocena25 + this.ocena33 + this.ocena42 + this.ocena54 + this.ocena67 + this.ocena71;
      OI = this.ocena13 + this.ocena27 + this.ocena34 + this.ocena45 + this.ocena58 + this.ocena61 + this.ocena76;
      OK = this.ocena11 + this.ocena23 + this.ocena36 + this.ocena47 + this.ocena55 + this.ocena68 + this.ocena74;
      S  = this.ocena18 + this.ocena24 + this.ocena37 + this.ocena43 + this.ocena51 + this.ocena65 + this.ocena72;
      CT = this.ocena12 + this.ocena26 + this.ocena35 + this.ocena41 + this.ocena53 + this.ocena62 + this.ocena78;
      P  = this.ocena15 + this.ocena28 + this.ocena32 + this.ocena46 + this.ocena57 + this.ocena64 + this.ocena73;

      let belbinData = {
        SO,
        RV,
        OA,
        OI,
        OK,
        S,
        CT,
        P,
        id:this.user._id
      }
      this.submitBelbin(belbinData).then(res=>{
        if(res.data.success){
          this.$router.push('/profile');
        }
      }).catch(err =>{
        console.log(err);
      });

    },
    increment11(){
      if(this.preostalo>0){
      this.ocena11+=1;
      this.preostalo-=1;
    }
    },
    decrement11(){
      if(this.ocena11>0){
      this.ocena11-=1;
      this.preostalo+=1;}
    },
    increment12(){
      if(this.preostalo>0){
      this.ocena12+=1;
      this.preostalo-=1;}
    },
    decrement12(){
      if(this.ocena12>0){
      this.ocena12-=1;
        this.preostalo+=1;}
    },
    increment13(){
      if(this.preostalo>0){
      this.ocena13+=1;
      this.preostalo-=1;}
    },
    decrement13(){
      if(this.ocena13>0){
      this.ocena13-=1;
        this.preostalo+=1;}
    },
    increment14(){
      if(this.preostalo>0){
      this.ocena14+=1;
      this.preostalo-=1;}
    },
    decrement14(){
      if(this.ocena14>0){
      this.ocena14-=1;
        this.preostalo+=1;}
    },
    increment15(){
      if(this.preostalo>0){
      this.ocena15+=1;
      this.preostalo-=1;}
    },
    decrement15(){
      if(this.ocena15>0){
      this.ocena15-=1;
      this.preostalo+=1;}
    },
    increment16(){
      if(this.preostalo>0){
      this.ocena16+=1;
      this.preostalo-=1;}
    },
    decrement16(){
      if(this.ocena16>0){
      this.ocena16-=1;
        this.preostalo+=1;}
    },
    increment17(){
      if(this.preostalo>0){
      this.ocena17+=1;
      this.preostalo-=1;}
    },
    decrement17(){
      if(this.ocena17>0){
      this.ocena17-=1;
        this.preostalo+=1;}
    },
    increment18(){
      if(this.preostalo>0){
      this.ocena18+=1;
      this.preostalo-=1;}
    },
    decrement18(){
      if(this.ocena18>0){
      this.ocena18-=1;
        this.preostalo+=1;}
    },
    /////////////////////
    increment21(){
      if(this.preostalo2>0){
      this.ocena21+=1;
      this.preostalo2-=1;
    }
    },
    decrement21(){
      if(this.ocena21>0){
      this.ocena21-=1;
      this.preostalo2+=1;}
    },
    increment22(){
      if(this.preostalo2>0){
      this.ocena22+=1;
      this.preostalo2-=1;}
    },
    decrement22(){
      if(this.ocena22>0){
      this.ocena22-=1;
        this.preostalo2+=1;}
    },
    increment23(){
      if(this.preostalo2>0){
      this.ocena23+=1;
      this.preostalo2-=1;}
    },
    decrement23(){
      if(this.ocena23>0){
      this.ocena23-=1;
        this.preostalo2+=1;}
    },
    increment24(){
      if(this.preostalo2>0){
      this.ocena24+=1;
      this.preostalo2-=1;}
    },
    decrement24(){
      if(this.ocena24>0){
      this.ocena24-=1;
        this.preostalo2+=1;}
    },
    increment25(){
      if(this.preostalo2>0){
      this.ocena25+=1;
      this.preostalo2-=1;}
    },
    decrement25(){
      if(this.ocena25>0){
      this.ocena25-=1;
      this.preostalo2+=1;}
    },
    increment26(){
      if(this.preostalo2>0){
      this.ocena26+=1;
      this.preostalo2-=1;}
    },
    decrement26(){
      if(this.ocena26>0){
      this.ocena26-=1;
        this.preostalo2+=1;}
    },
    increment27(){
      if(this.preostalo2>0){
      this.ocena27+=1;
      this.preostalo2-=1;}
    },
    decrement27(){
      if(this.ocena27>0){
      this.ocena27-=1;
        this.preostalo2+=1;}
    },
    increment28(){
      if(this.preostalo2>0){
      this.ocena28+=1;
      this.preostalo2-=1;}
    },
    decrement28(){
      if(this.ocena28>0){
      this.ocena28-=1;
        this.preostalo2+=1;}
    },
    /////////////////////
    increment31(){
      if(this.preostalo3>0){
      this.ocena31+=1;
      this.preostalo3-=1;
    }
    },
    decrement31(){
      if(this.ocena31>0){
      this.ocena31-=1;
      this.preostalo3+=1;}
    },
    increment32(){
      if(this.preostalo3>0){
      this.ocena32+=1;
      this.preostalo3-=1;}
    },
    decrement32(){
      if(this.ocena32>0){
      this.ocena32-=1;
        this.preostalo3+=1;}
    },
    increment33(){
      if(this.preostalo3>0){
      this.ocena33+=1;
      this.preostalo3-=1;}
    },
    decrement33(){
      if(this.ocena33>0){
      this.ocena33-=1;
        this.preostalo3+=1;}
    },
    increment34(){
      if(this.preostalo3>0){
      this.ocena34+=1;
      this.preostalo3-=1;}
    },
    decrement34(){
      if(this.ocena34>0){
      this.ocena34-=1;
        this.preostalo3+=1;}
    },
    increment35(){
      if(this.preostalo3>0){
      this.ocena35+=1;
      this.preostalo3-=1;}
    },
    decrement35(){
      if(this.ocena35>0){
      this.ocena35-=1;
      this.preostalo3+=1;}
    },
    increment36(){
      if(this.preostalo3>0){
      this.ocena36+=1;
      this.preostalo3-=1;}
    },
    decrement36(){
      if(this.ocena36>0){
      this.ocena36-=1;
        this.preostalo3+=1;}
    },
    increment37(){
      if(this.preostalo3>0){
      this.ocena37+=1;
      this.preostalo3-=1;}
    },
    decrement37(){
      if(this.ocena37>0){
      this.ocena37-=1;
        this.preostalo3+=1;}
    },
    increment38(){
      if(this.preostalo3>0){
      this.ocena38+=1;
      this.preostalo3-=1;}
    },
    decrement38(){
      if(this.ocena38>0){
      this.ocena38-=1;
        this.preostalo3+=1;}
    },
    /////////////////////
    increment41(){
      if(this.preostalo4>0){
      this.ocena41+=1;
      this.preostalo4-=1;
    }
    },
    decrement41(){
      if(this.ocena41>0){
      this.ocena41-=1;
      this.preostalo4+=1;}
    },
    increment42(){
      if(this.preostalo4>0){
      this.ocena42+=1;
      this.preostalo4-=1;}
    },
    decrement42(){
      if(this.ocena42>0){
      this.ocena42-=1;
        this.preostalo4+=1;}
    },
    increment43(){
      if(this.preostalo4>0){
      this.ocena43+=1;
      this.preostalo4-=1;}
    },
    decrement43(){
      if(this.ocena43>0){
      this.ocena43-=1;
        this.preostalo4+=1;}
    },
    increment44(){
      if(this.preostalo4>0){
      this.ocena44+=1;
      this.preostalo4-=1;}
    },
    decrement44(){
      if(this.ocena44>0){
      this.ocena44-=1;
        this.preostalo4+=1;}
    },
    increment45(){
      if(this.preostalo4>0){
      this.ocena45+=1;
      this.preostalo4-=1;}
    },
    decrement45(){
      if(this.ocena45>0){
      this.ocena45-=1;
      this.preostalo4+=1;}
    },
    increment46(){
      if(this.preostalo4>0){
      this.ocena46+=1;
      this.preostalo4-=1;}
    },
    decrement46(){
      if(this.ocena46>0){
      this.ocena46-=1;
        this.preostalo4+=1;}
    },
    increment47(){
      if(this.preostalo4>0){
      this.ocena47+=1;
      this.preostalo4-=1;}
    },
    decrement47(){
      if(this.ocena47>0){
      this.ocena47-=1;
        this.preostalo4+=1;}
    },
    increment48(){
      if(this.preostalo4>0){
      this.ocena48+=1;
      this.preostalo4-=1;}
    },
    decrement48(){
      if(this.ocena48>0){
      this.ocena48-=1;
        this.preostalo4+=1;}
    },

    /////////////////////
    increment51(){
      if(this.preostalo5>0){
      this.ocena51+=1;
      this.preostalo5-=1;
    }
    },
    decrement51(){
      if(this.ocena51>0){
      this.ocena51-=1;
      this.preostalo5+=1;}
    },
    increment52(){
      if(this.preostalo5>0){
      this.ocena52+=1;
      this.preostalo5-=1;}
    },
    decrement52(){
      if(this.ocena52>0){
      this.ocena52-=1;
        this.preostalo5+=1;}
    },
    increment53(){
      if(this.preostalo5>0){
      this.ocena53+=1;
      this.preostalo5-=1;}
    },
    decrement53(){
      if(this.ocena53>0){
      this.ocena53-=1;
        this.preostalo5+=1;}
    },
    increment54(){
      if(this.preostalo5>0){
      this.ocena54+=1;
      this.preostalo5-=1;}
    },
    decrement54(){
      if(this.ocena54>0){
      this.ocena54-=1;
        this.preostalo5+=1;}
    },
    increment55(){
      if(this.preostalo5>0){
      this.ocena55+=1;
      this.preostalo5-=1;}
    },
    decrement55(){
      if(this.ocena55>0){
      this.ocena55-=1;
      this.preostalo5+=1;}
    },
    increment56(){
      if(this.preostalo5>0){
      this.ocena56+=1;
      this.preostalo5-=1;}
    },
    decrement56(){
      if(this.ocena56>0){
      this.ocena56-=1;
        this.preostalo5+=1;}
    },
    increment57(){
      if(this.preostalo5>0){
      this.ocena57+=1;
      this.preostalo5-=1;}
    },
    decrement57(){
      if(this.ocena57>0){
      this.ocena57-=1;
        this.preostalo5+=1;}
    },
    increment58(){
      if(this.preostalo5>0){
      this.ocena58+=1;
      this.preostalo5-=1;}
    },
    decrement58(){
      if(this.ocena58>0){
      this.ocena58-=1;
        this.preostalo5+=1;}
    },
    /////////////////////
    increment61(){
      if(this.preostalo6>0){
      this.ocena61+=1;
      this.preostalo6-=1;
    }
    },
    decrement61(){
      if(this.ocena61>0){
      this.ocena61-=1;
      this.preostalo6+=1;}
    },
    increment62(){
      if(this.preostalo6>0){
      this.ocena62+=1;
      this.preostalo6-=1;}
    },
    decrement62(){
      if(this.ocena62>0){
      this.ocena62-=1;
        this.preostalo6+=1;}
    },
    increment63(){
      if(this.preostalo6>0){
      this.ocena63+=1;
      this.preostalo6-=1;}
    },
    decrement63(){
      if(this.ocena63>0){
      this.ocena63-=1;
        this.preostalo6+=1;}
    },
    increment64(){
      if(this.preostalo6>0){
      this.ocena64+=1;
      this.preostalo6-=1;}
    },
    decrement64(){
      if(this.ocena64>0){
      this.ocena64-=1;
        this.preostalo6+=1;}
    },
    increment65(){
      if(this.preostalo6>0){
      this.ocena65+=1;
      this.preostalo6-=1;}
    },
    decrement65(){
      if(this.ocena65>0){
      this.ocena65-=1;
      this.preostalo6+=1;}
    },
    increment66(){
      if(this.preostalo6>0){
      this.ocena66+=1;
      this.preostalo6-=1;}
    },
    decrement66(){
      if(this.ocena66>0){
      this.ocena66-=1;
        this.preostalo6+=1;}
    },
    increment67(){
      if(this.preostalo6>0){
      this.ocena67+=1;
      this.preostalo6-=1;}
    },
    decrement67(){
      if(this.ocena67>0){
      this.ocena67-=1;
        this.preostalo6+=1;}
    },
    increment68(){
      if(this.preostalo6>0){
      this.ocena68+=1;
      this.preostalo6-=1;}
    },
    decrement68(){
      if(this.ocena68>0){
      this.ocena68-=1;
        this.preostalo6+=1;}
    },
    /////////////////////
    increment71(){
      if(this.preostalo7>0){
      this.ocena71+=1;
      this.preostalo7-=1;
    }
    },
    decrement71(){
      if(this.ocena71>0){
      this.ocena71-=1;
      this.preostalo7+=1;}
    },
    increment72(){
      if(this.preostalo7>0){
      this.ocena72+=1;
      this.preostalo7-=1;}
    },
    decrement72(){
      if(this.ocena72>0){
      this.ocena72-=1;
        this.preostalo7+=1;}
    },
    increment73(){
      if(this.preostalo7>0){
      this.ocena73+=1;
      this.preostalo7-=1;}
    },
    decrement73(){
      if(this.ocena73>0){
      this.ocena73-=1;
        this.preostalo7+=1;}
    },
    increment74(){
      if(this.preostalo7>0){
      this.ocena74+=1;
      this.preostalo7-=1;}
    },
    decrement74(){
      if(this.ocena74>0){
      this.ocena74-=1;
        this.preostalo7+=1;}
    },
    increment75(){
      if(this.preostalo7>0){
      this.ocena75+=1;
      this.preostalo7-=1;}
    },
    decrement75(){
      if(this.ocena75>0){
      this.ocena75-=1;
      this.preostalo7+=1;}
    },
    increment76(){
      if(this.preostalo7>0){
      this.ocena76+=1;
      this.preostalo7-=1;}
    },
    decrement76(){
      if(this.ocena76>0){
      this.ocena76-=1;
        this.preostalo7+=1;}
    },
    increment77(){
      if(this.preostalo7>0){
      this.ocena77+=1;
      this.preostalo7-=1;}
    },
    decrement77(){
      if(this.ocena77>0){
      this.ocena77-=1;
        this.preostalo7+=1;}
    },
    increment78(){
      if(this.preostalo7>0){
      this.ocena78+=1;
      this.preostalo7-=1;}
    },
    decrement78(){
      if(this.ocena78>0){
      this.ocena78-=1;
        this.preostalo7+=1;}
    },
  },
  beforeCreate: function() {
        document.body.className = 'other';
    }

}
</script>

<style lang="css" scoped>
table, th, td{
  border: 1px solid black;
  text-align: center;
  margin: auto;
  margin-top: 1em;
}

tr:nth-child(even){background-color: #bbbbbb;}
tr:nth-child(odd){background-color: #ffffff;}
tr:hover {background-color: #afa;}
.stazH1 { grid-area: stazH1; }
.stazTable { grid-area: stazTable; }
.stazGraph { grid-area: stazGraph; }
.demografija{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
}
.ocenaLbl{
  width: 50px;
}
h1,h3{
  text-align: center;
}
.header{
  background-color: #ffaaaa;
  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
}

#t1{
  background-color: #ffaaaa;
  border-style: solid;
  border-color: black;
  border-width: medium;

  border-radius: 15px;
}
#t2{
  background-color: #ffaaaa;
  border-style: solid;
  border-color: black;
  border-width: medium;

  border-radius: 15px;
}
#t3{
  background-color: #ffaaaa;
  border-style: solid;
  border-color: black;
  border-width: medium;

  border-radius: 15px;
}
.submitDiv{
  width: 100%;
}
.btn{
  width: 100%;
  background-color: #003366;
}

</style>
